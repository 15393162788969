<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Employee Attendance</h4>
                        </div>
                        <div class="col-sm-4 text-right">
                            <!-- <p class="mb-0 fs-4">{{ workingDate | formatFullDate }}</p> -->
                        </div>
                        <div class="col-sm-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row justify-content-md-center">
                <div class="col-lg-9 col-xl-9 ">
                    <EmpAttendanceTimelineComponent />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EmpAttendanceTimelineComponent from '@/components/common/EmpAttendanceTimelineComponent.vue';
export default {
    name: 'app',
    components: {
        EmpAttendanceTimelineComponent,
    },
    data() {
        return {
        }
    }
}
</script>