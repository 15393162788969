<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Projects 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart v-if="projectCount" :styles="myStyles" :chartdata="projectByStatusData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No Project Found
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Clients
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Clients">{{ clientCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart v-if="clientCount" :styles="myStyles" :chartdata="clientByTypeData" :options="chartOptions" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No Client Found
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Resource Pool
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ resourceCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body py-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="resourcePoolData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Users
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="userByTypeData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="form-row mb-2">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-6 col-xl-6">
                                            <div class="card p-3">
                                                <div class="form-row">
                                                    <div class="col-3">
                                                        <p class="chart-title text-left pl-1 mb-1">Project Plan</p>
                                                    </div>
                                                    <div class="col-9 text-right">
                                                        <template>
                                                            <b-avatar text="M" title="Month" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('m')"></b-avatar>
                                                            <b-avatar text="W" title="Week" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('w')"></b-avatar>
                                                            <b-avatar text="D" title="Day" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('d')"></b-avatar>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <gantt class="gantt" :tasks="tasks" :ganttChartScales="ganttChartScales" @task-updated="logTaskUpdate" @link-updated="logLinkUpdate" @task-selected="selectTask" :key="ganttChartKey"></gantt>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-xl-6">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Project Budget</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="projectBudgetSummary" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-row">
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Employees
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ employeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body py-1 px-0 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="employeeData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">Emp. Work Type</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <PieChart :styles="myStyles" :chartdata="workTypeChartData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">Gender Distribution</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="genderDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">Age Distribution</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="ageDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <!-- <div class="col-md-4 col-xl-4">
                                            <div class="card mb-4 p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Work Type</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body py-1">
                                                    <PieChart :styles="myStyles" :chartdata="workTypeChartData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Client Requiement</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="userRequirementStatusSummary" :options="stackedBarChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Requirements 
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectRequirementCount }}</span>
                                                </p>
                                                <!-- <p class="chart-title text-left pl-1 mb-1">Requirements</p> -->
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="requirementSpecStatusSummary" :options="stackedBarChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Issues 
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectIssueCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="projectIssueStatusSummary" :options="stackedBarChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  <div class="col-md-4 col-xl-4">
                                            <div class="card mb-4 p-3">
                                                <p class="chart-title text-left">Scope</p>
                                                <div class="card-body d-flex p-0">
                                                    <PieChart :chartdata="genderDistributionData" :options="chartOptions" class="d-block w-100-h-350" />
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-md-4 col-xl-4">
                                            <div class="card p-1 h-100">
                                                <p class="chart-title text-left pl-1 mb-1">Users</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="userByTypeData" :options="chartOptionsLarge" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import DoughnutChart from "@/components/blocks/charts/DoughnutChart.vue";
import Gantt from "@/components/blocks/charts/GanttChart.vue"
import HorizontalBarChart from "@/components/blocks/charts/HorizontalBarChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";
export default {
    name: "App",
    components: {
        BarChart,
        DoughnutChart,
        Gantt,
        HorizontalBarChart,
        PieChart,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),


            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            projectCount: 0,
            resourceCount: 0,
            employeeCount: 0,
            userCount: 0,
            clientCount: 0,

            projectRequirementCount: 0,
            projectIssueCount: 0,

            requirementSpecStatusSummary: {},
            userRequirementStatusSummary: {},
            projectIssueStatusSummary: {},
            projectBudgetSummary: {},
            resourcePoolData: {},
            employeeData: {},
            workTypeChartData: {},
            genderDistributionData: {},
            ageDistributionData: {},
            projectByStatusData: {},
            clientByTypeData: {},
            userByTypeData: {},

            chartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'right',
                },
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/          },
            chartOptionsLarge: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        // stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                    }]
                }
            },
            stackedBarChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []

        }
    },
    computed: {
        myStyles() {
            return {
                height: '80px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
        myStyles2() {
            return {
                height: '72px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
    },
    mounted() {
        this.setGanttChartScale('m');
        this.getDashboardData();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: this.userId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/prmdata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    // console.log(result);
                    // if (result.employeeAttendanceSummary.length>0) {
                    //     this.employeeAttendanceSummary = result.employeeAttendanceSummary[0];
                    // }

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    // if (result.employeeByMonth != null) {
                    //     this.requirementSpecStatusSummary = {
                    //         labels: ['Web Project', 'Mobile Project', 'Demo Project'],
                    //         datasets: [{
                    //                 label: ['Open', 'Closed', 'Draft',],
                    //                 backgroundColor: '#2a93d5',
                    //                 data: [2, 5, 9],
                    //             },
                    //             {
                    //                 label: ['Open', 'Closed', 'Draft',],
                    //                 backgroundColor: 'green',
                    //                 data: [2, 5, 9],
                    //             },
                    //         ]
                    //     }
                    // }
                    if (result.requirementSpecStatusSummary != null && result.requirementSpecStatusSummary.length != 0) {

                        // Initialize total counts for each status
                        const totalCounts = {
                            draft: 0,
                            open: 0,
                            started: 0,
                            inProgress: 0,
                            completed: 0,
                            verified: 0,
                            reopened: 0,
                            closed: 0,
                        };

                        // Calculate total counts for each status
                        result.requirementSpecStatusSummary.forEach(item => {
                            console.log("Processing Item:", item); // Debug: Log each item
                            totalCounts.draft += item.draftCount || 0;
                            totalCounts.open += item.openCount || 0;
                            totalCounts.started += item.startedCount || 0;
                            totalCounts.inProgress += item.inProgressCount || 0;
                            totalCounts.completed += item.completedCount || 0;
                            totalCounts.verified += item.verifiedCount || 0;
                            totalCounts.reopened += item.reopenedCount || 0;
                            totalCounts.closed += item.closedCount || 0;
                        });

                        console.log("Total Counts:", totalCounts); // Debug: Log final total counts

                        // Calculate total project requirement count
                        this.projectRequirementCount += Object.values(totalCounts).reduce((total, count) => total + count, 0);




                        // this.elements.noReqSpecData = false;
                        this.requirementSpecStatusSummary = {
                            labels: result.requirementSpecStatusSummary.map(item => item.title),
                            datasets: [{
                                  label: `Draft (${totalCounts.draft})` ,
                                  backgroundColor: "grey",
                                  data: result.requirementSpecStatusSummary.map(item => item.draftCount),
                                },
                                {
                                  label: `Open (${totalCounts.open})`,
                                  backgroundColor: "#47799a",
                                  data: result.requirementSpecStatusSummary.map(item => item.openCount),
                                },
                                {
                                  label: `Started (${totalCounts.started})`,
                                  backgroundColor: "#3db9d3",
                                  data: result.requirementSpecStatusSummary.map(item => item.startedCount),
                                },
                                {
                                  label: `In Progress (${totalCounts.inProgress})`,
                                  backgroundColor: "violet",
                                  data: result.requirementSpecStatusSummary.map(item => item.inProgressCount),
                                },
                                {
                                  label: `Completed (${totalCounts.completed})`,
                                  backgroundColor: "yellow",
                                  data: result.requirementSpecStatusSummary.map(item => item.completedCount),
                                },
                                {
                                  label: `Verified (${totalCounts.verified})`,
                                  backgroundColor: "orange",
                                  data: result.requirementSpecStatusSummary.map(item => item.verifiedCount),
                                },
                                {
                                  label: `Reopened (${totalCounts.reopened})`,
                                  backgroundColor: "red",
                                  data: result.requirementSpecStatusSummary.map(item => item.reopenedCount),
                                },
                                {
                                  label: `Closed (${totalCounts.closed})`,
                                  backgroundColor: "green",
                                  data: result.requirementSpecStatusSummary.map(item => item.closedCount),
                                },
                                
                            ]
                        }
                    }
                    if (result.userRequirementStatusSummary != null && result.userRequirementStatusSummary.length != 0) {
                        // this.elements.noReqSpecData = false;
                        this.userRequirementStatusSummary = {
                            labels: result.userRequirementStatusSummary.map(item => item.title),
                            datasets: [{
                                  label: 'Draft',
                                  backgroundColor: "grey",
                                  data: result.userRequirementStatusSummary.map(item => item.draftCount),
                                },
                                {
                                  label: 'Open',
                                  backgroundColor: "#47799a",
                                  data: result.userRequirementStatusSummary.map(item => item.openCount),
                                },
                                // {
                                //   label: 'Started',
                                //   backgroundColor: "#2a93d5",
                                //   data: result.userRequirementStatusSummary.map(item => item.startedCount),
                                // },
                                {
                                  label: 'In Progress',
                                  backgroundColor: "violet",
                                  data: result.userRequirementStatusSummary.map(item => item.inProgressCount),
                                },
                                {
                                  label: 'Completed',
                                  backgroundColor: "yellow",
                                  data: result.userRequirementStatusSummary.map(item => item.completedCount),
                                },
                                {
                                  label: 'Verified',
                                  backgroundColor: "orange",
                                  data: result.userRequirementStatusSummary.map(item => item.verifiedCount),
                                },
                                // {
                                //   label: 'Reopened',
                                //   backgroundColor: "red",
                                //   data: result.userRequirementStatusSummary.map(item => item.reopenedCount),
                                // },
                                {
                                  label: 'Closed',
                                  backgroundColor: "green",
                                  data: result.userRequirementStatusSummary.map(item => item.closedCount),
                                },
                            ]
                        }
                    }
                    if (result.projectIssueStatusSummary != null && result.projectIssueStatusSummary.length != 0) {

                        // Initialize total counts for each status
                        const totalCounts = {
                            draft: 0,
                            newIssue: 0,
                            // started: 0,
                            analyzed: 0,
                            resolved: 0,
                            verified: 0,
                            reopened: 0,
                            closed: 0,
                        };

                        // Calculate total counts for each status
                        result.projectIssueStatusSummary.forEach(item => {
                            console.log("Processing Item:", item); // Debug: Log each item
                            totalCounts.draft += item.draftCount || 0;
                            totalCounts.newIssue += item.openCount || 0;
                            // totalCounts.started += item.startedCount || 0;
                            totalCounts.analyzed += item.inProgressCount || 0;
                            totalCounts.resolved += item.completedCount || 0;
                            totalCounts.verified += item.verifiedCount || 0;
                            totalCounts.reopened += item.reopenedCount || 0;
                            totalCounts.closed += item.closedCount || 0;
                        });

                        console.log("Total Counts:", totalCounts); // Debug: Log final total counts

                        // Calculate total project requirement count
                        this.projectIssueCount += Object.values(totalCounts).reduce((total, count) => total + count, 0);
                        // this.elements.noReqSpecData = false;
                        this.projectIssueStatusSummary = {
                            labels: result.projectIssueStatusSummary.map(item => item.title),
                            datasets: [{
                                  label: `Draft (${totalCounts.draft})`,
                                  backgroundColor: "grey",
                                  data: result.projectIssueStatusSummary.map(item => item.draftCount),
                                },
                                {
                                  label: `New Issue (${totalCounts.newIssue})`,
                                  backgroundColor: "#47799a",
                                  data: result.projectIssueStatusSummary.map(item => item.openCount),
                                },
                                {
                                  label: `Analyzed (${totalCounts.analyzed})`,
                                  backgroundColor: "violet",
                                  data: result.projectIssueStatusSummary.map(item => item.inProgressCount),
                                },
                                {
                                  label: `Resolved (${totalCounts.resolved})`,
                                  backgroundColor: "yellow",
                                  data: result.projectIssueStatusSummary.map(item => item.completedCount),
                                },
                                {
                                  label: `Verified (${totalCounts.verified})`,
                                  backgroundColor: "orange",
                                  data: result.projectIssueStatusSummary.map(item => item.verifiedCount),
                                },
                                {
                                  label: `Reopened (${totalCounts.reopened})`,
                                  backgroundColor: "red",
                                  data: result.projectIssueStatusSummary.map(item => item.reopenedCount),
                                },
                                {
                                  label: `Closed (${totalCounts.closed})`,
                                  backgroundColor: "green",
                                  data: result.projectIssueStatusSummary.map(item => item.closedCount),
                                },
                            ]
                        }
                    }
                    // if (result.salaryByMonth != null) {
                    //     this.salaryByMonthData = {
                    //         labels: result.salaryByMonth.map(item => item.dateString),
                    //         datasets: [{
                    //                 label: 'Salary',
                    //                 backgroundColor: '#2a93d5',
                    //                 data: result.salaryByMonth.map(item => item.moneyValue),
                    //             }
                    //         ]
                    //     }

                    // }
                    // if (result.empUtilizationByDepartment != null) {
                    //     this.employeeUtilizationData = {
                    //         labels: result.empUtilizationByDepartment.map(item => item.department),
                    //         datasets: [{
                    //                 label: 'Average Utilization',
                    //                 backgroundColor: '#2a93d5',
                    //                 data: result.empUtilizationByDepartment.map(item => item.averageEmpUtilization),
                    //             }
                    //         ]
                    //     }
                    // }
                    // this.resourcePoolData = {
                    //     labels: ['Others','Employees'],
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.resourcePoolData.map(item => {
                    //             this.resourceCount += Number(item.resourceCount);
                    //             return item.resourceCount;
                    //         })
                    //     }]
                    // }
                    // this.employeeData = {
                    //     labels: result.employeeData.map(item => item.department),
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.employeeData.map(item => {
                    //             this.employeeCount += Number(item.empCount);
                    //             return item.empCount;
                    //         })
                    //     }]
                    // }
                    this.projectByStatusData = {
                        labels: result.projectByStatus.map(item => item.status),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.projectByStatus.map((item) => {
                                this.projectCount += Number(item.empCount);
                                return item.empCount;
                            })
                        }]
                    }
                    if (result.prmDashboardProjectList != null) {
                        this.tasks = {
                            data: result.prmDashboardProjectList,
                            links: []
                        }
                        let dateNow = new Date();
                        this.tasks.data = this.tasks.data.map((item) => {
                            item.text = item.displayName ? item.displayName : item.title;
                            item.start_date = new Date(item.startDate);
                            item.end_date = new Date(item.endDate);
                            item.progress = Math.round(((item.end_date - item.start_date) / (item.start_date - dateNow)) * 100);
                            return item;
                        })
                    }
                    if (result.projectBudgetSummary != null) {
                        this.projectBudgetSummary = {
                            labels: result.projectBudgetSummary.map(item => item.displayName ? item.displayName : item.title),
                            datasets: [{
                                    label: 'Estimated',
                                    backgroundColor: '#28a745',
                                    data: result.projectBudgetSummary.map(item => item.estimatedAmount)
                                },
                                {
                                    label: 'Actual',
                                    backgroundColor: '#d85a5a',
                                    // borderColor: 'rgba(255, 26, 104, 1)',
                                    // borderWidth: 1,
                                    data: result.projectBudgetSummary.map(item => item.actualAmount)

                                }
                            ]
                        }

                    }
                    // this.userByTypeData = {
                    //     labels: result.userByType.map(item => item.type),
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a', '#4dcac9', '#cac04d'],
                    //         data: result.userByType.map(item => {
                    //             this.userCount += Number(item.userCount);
                    //             return item.userCount;
                    //         })
                    //     }]
                    // }
                    this.clientByTypeData = {
                        labels: result.clientByType.map(item => item.type),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.clientByType.map(item => {
                                this.clientCount += Number(item.clientCount);
                                return item.clientCount;
                            })
                        }]
                    }
                    //     console.log(result.workTypeData)
                    // this.workTypeChartData = {
                    //     labels: result.workTypeData.map(item => item.workType),
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.workTypeData.map(item => item.resourceCount)
                    //     }]
                    // }

                    // this.genderDistributionData = {
                    //     labels: result.genderDistributionData.map(item => item.gender),
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.genderDistributionData.map(item => item.empCount)
                    //     }]
                    // }
                    // this.ageDistributionData = {
                    //     labels: ['Under 30', '31 - 50', 'Above 50' ],
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.ageDistributionData.map(item => item.age),
                    //     }]
                    // }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        selectTask(task) {
            this.selectedTask = task;
        },

        addMessage(message) {
            this.messages.unshift(message)
            if (this.messages.length > 40) {
                this.messages.pop()
            }
        },
        setGanttChartScale(scale) {
            if (scale == 'd') {
                // alert(scale);
                this.ganttChartScales = 'day';
                this.ganttChartKey += 1;                        
            }
            else if (scale == 'w') {
                this.ganttChartScales = 'week';
                this.ganttChartKey += 1;                        
                // alert(scale);
            }
            else if (scale == 'm') {
                this.ganttChartScales = 'month';
                this.ganttChartKey += 1;                        
                // alert(scale);
            }

        },
        logTaskUpdate(id, mode, task) {
            let text = (task && task.text ? ` (${task.text})` : '')
            let message = `Task ${mode}: ${id} ${text}`
            this.addMessage(message)
        },

        logLinkUpdate(id, mode, link) {
            let message = `Link ${mode}: ${id}`
            if (link) {
                message += ` ( source: ${link.source}, target: ${link.target} )`
            }
            this.addMessage(message)
        }
    }
}
</script>