export default {
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
        }
    },
    methods: {
        getLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position)=> {
                        	const location = {
                        		latitude: position.coords.latitude,
                            	longitude: position.coords.longitude,
                        	}
                            
                            console.log("POS",position);
                            console.log("LOC",location);

                            resolve(location);
                        },  // Success callback
                        (error)=> {

                            console.error("Error getting location: ", error.message);
                            resolve(null);

                        },    // Error callback

                         { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }  // Higher accuracy and quick timeout
                    );
                } else {

                    console.error("Geolocation is not supported by this browser.");
                    resolve(null);
                    
                }
            });
        },
        async getPlaceName(location) {
          const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.latitude}&lon=${location.longitude}`;
          
          try {
          	console.log("LOC2",location);
            const response = await fetch(url, {
              headers: {
                'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64)',  // Required by Nominatim to prevent request blocking
              }
            });
            const data = await response.json();
            return data.display_name;
          } catch (error) {
            return "";
          }
        },

    }
};