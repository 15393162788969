<template>
    <div>
        Contacts
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Select2
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            defaultPhoto: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',

            elements: {
                editMode: false,
                visible: false,
                showProgress: false,
            },
            contact: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                userId: localStorage.getItem("userId"),
                addedBy: '',
                contactName: '',
                position: '',
                emailId: '',
                mobile: '',
                phone: '',
                firstName: '',
                middleName: '',
                lastName: '',
                alternateEmailId: '',
                alternateMobile: '',
                officePhone: '',
                photo: '',
                countryId: '',
                country: '',
                address1: '',
                streetName: '',
                city: '',
                state: '',
                pincode: '',
                website: '',
                facebookId: '',
                gender: '',
                dateOfBirth: '',
                refEntity: 'contact',


            },
            notEnabled: true,
            countries: [],
            timeZones: [],
            // contact: {
            //     addedBy: ''
            // },
            contactDetail: {
                id: '',
                contactId: '',
                contactName: '',
                addedBy: '',
                firstName: '',
                middleName: '',
                lastName: '',
                company: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                streetName: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                timeZone: '',
                website: '',
                facebookId: '',
                gender: '',
                dateOfBirth: '',
                photo: '',
                active: '',
                createdDt: '',
                lastUpdatedDt: '',
                photo: this.defaultPhoto,
                refEntity: 'contact',

            },
            genderOptions: [
              { text: 'Male', value: 'M' },
              { text: 'Female', value: 'F' },
              { text: 'Transgender', value: 'T' }
            ],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            contacts: [],
            fields: [
                // A virtual column that doesn't exist in items
                // { key: 'index', label: 'SN' },
                { key: 'contactName', label: 'Contact Name', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Job Title', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email', sortable: true, sortDirection: 'desc' },
                { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
                // { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                { key: 'city', label: 'Location', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions'}
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.lookupData();
        this.getContact();
    },
    validations: {
        contact: {
            contactName: {
                required
            }
        }
    },
    methods: {
        getContact: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            // alert('a');
            axios.post(this.$store.getters.getAPIBasePath + '/contact/user/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);

                    this.contacts = result.userContactList;
                    if (this.contacts != null) {
                        this.totalRows = Array.isArray(this.contacts) ? this.contacts.length : 0;
                        this.$emit('updateContactCount', this.totalRows);
                        this.onRowSelected(this.contacts);
                        // alert(this.projectId);
                    }

                    // if (result && Array.isArray(result.userContactList)) {
                    //     this.contacts = result.userContactList;
                    //     if (this.contacts.length > 0) {
                    //         alert(this.contacts.length);
                    //         this.bindSelectedData(this.contactDetail, this.contacts[0]);
                    //         this.totalRows = this.contacts.length;
                    //         this.$emit('updateContactCount', this.totalRows);
                    //         console.log(this.contacts);
                    //     }
                        
                    // }
                    this.elements.showProgress = true;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/contact/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.countries = result.countryLookup;
                    this.timeZones = result.timeZoneLookup;
                })
        },
        saveData: function() {
            console.log(this.contact);
            this.$v.contact.$touch();
            if (!this.$v.contact.$invalid) {
                this.showOverlay = true;
                if (this.elements.editMode) {
                    this.updateContact();
                } else {
                    this.saveContact();
                }
            }
        },
        saveContact: function() {
            console.log(this.contact);
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contact)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateContact: function() {
            console.log(this.contact);
            // return;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contact)
                .then((response) => {
                    this.showOverlay = false;
                    console.log(this.contact);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();                        
                        this.viewContact(this.contact.id);
                        this.clearData();


                    }
                    console.log(result);
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewContact: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/contact/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.bindSelectedData(this.contactDetail, result.contactView);
                })

        },
        deleteContact: function(id) {
            this.deleteParam.id = id;
            console.log(this.deleteParam.id);
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/contact/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        clearData: function() {

            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.contact.id = '';
            this.contact.contactName = '';
            this.contact.active = '';
            this.contact.position = '';
            this.contact.emailId = '';
            this.contact.mobile = '';
            this.contact.firstName = '';
            this.contact.middleName = '';
            this.contact.lastName = '';
            this.contact.photo = '';
            this.contact.countryId = '';
            this.contact.country = '';
            this.contact.buildingName = '';
            this.contact.streetName = '';
            this.contact.city = '';
            this.contact.state = '';
            this.contact.pincode = '';
            this.contact.website = '';
            this.contact.addedBy = '';
            this.contact.address1 = '';
            this.contact.streetName = '';
            this.contact.alternateEmailId = '';
            this.contact.alternateMobile = '';
            this.contact.company = '';
            this.contact.contactId = '';
            this.contact.dateOfBirth = '';
            this.contact.facebookId = '';
            this.contact.skypeId = '';
            this.contact.gender = '';
            this.contact.linkedinId = '';
            this.contact.officePhone = '';
            this.contact.phone = '';
            this.contact.timeZone = '';
            this.contact.twitterId = '';
            this.$v.contact.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.bindSelectedData(this.contact, data);
            /*  this.contact.id = data.id;
              this.contact.contactName = data.contactName;
              this.contact.active = data.active;
              this.contact.position = data.position;
              this.contact.emailId = data.emailId;
              this.contact.mobile = data.mobile;
              this.contact.firstName = data.firstName;
              this.contact.middleName = data.middleName;
              this.contact.lastName = data.lastName;
              this.contact.photo = data.photo;
              this.contact.countryId = data.countryId;
              this.contact.country = data.country;
              this.contact.buildingName = data.buildingName;
              this.contact.streetName = data.streetName;
              this.contact.city = data.city;
              this.contact.state = data.state;
              this.contact.pincode = data.pincode;
              this.contact.website = data.website;
              this.contact.addedBy = data.addedBy;
              this.contact.address1 = data.address1;
              this.contact.address2 = data.address2;
              this.contact.alternateEmailId = data.alternateEmailId;
              this.contact.alternateMobile = data.alternateMobile;
              this.contact.company = data.company;
              this.contact.contactId = data.contactId;
              this.contact.dateOfBirth = data.dateOfBirth;
              this.contact.facebookId = data.facebookId;
              this.contact.skypeId = data.skypeId;
              this.contact.gender = data.gender;
              this.contact.linkedinId = data.linkedinId;
              this.contact.officePhone = data.officePhone;
              this.contact.phone = data.phone;
              this.contact.refEntityId = data.refEntityId;
              this.contact.refRecordId = data.refRecordId;
              this.contact.timeZone = data.timeZone;
              this.contact.twitterId = data.twitterId;
              this.contact.userId = data.userId;
              this.contact.vhextAccountId = data.vhextAccountId;*/
        },
        showView: function(data) {
            this.elements.visible = false;
            this.elements.viewMode = true;
            this.bindSelectedData(this.contactDetail, data);
        },
        onRowSelected: function(items) {
            console.log(items);
            // alert('a');
            if (items !== null && items.length !== 0) {
                let data = items[0];
                
                // this.elements.visible = true;
                // this.bindSelectedData(this.contactDetail, data);
                this.contactDetail.vhextAccountId = data.vhextAccountId;
                this.contactDetail.userId = data.userId;

                this.contactDetail.id = data.id;
                this.contactDetail.contactName = data.contactName;
                this.contactDetail.firstName = data.firstName;
                this.contactDetail.middleName = data.middleName;
                this.contactDetail.lastName = data.lastName;
                this.contactDetail.photo = data.photo == null ? this.defaultPhoto : data.photo;
                this.contactDetail.position = data.position;
                this.contactDetail.emailId = data.emailId;
                this.contactDetail.mobile = data.mobile;
                this.contactDetail.countryId = data.countryId;
                this.contactDetail.country = data.country;
                this.contactDetail.buildingName = data.buildingName;
                this.contactDetail.streetName = data.streetName;
                this.contactDetail.city = data.city;
                this.contactDetail.state = data.state;
                this.contactDetail.pincode = data.pincode;
                this.contactDetail.website = data.website;
                this.contactDetail.addedBy = data.addedBy;
                this.contactDetail.address1 = data.address1;
                this.contactDetail.streetName = data.streetName;
                this.contactDetail.alternateEmailId = data.alternateEmailId;
                this.contactDetail.alternateMobile = data.alternateMobile;
                this.contactDetail.company = data.company;
                this.contactDetail.contactId = data.contactId;
                this.contactDetail.dateOfBirth = data.dateOfBirth;
                this.contactDetail.facebookId = data.facebookId;
                this.contactDetail.skypeId = data.skypeId;
                this.contactDetail.gender = data.gender;
                this.contactDetail.linkedinId = data.linkedinId;
                this.contactDetail.officePhone = data.officePhone;
                this.contactDetail.phone = data.phone;
                this.contactDetail.refEntityId = data.refEntityId;
                this.contactDetail.refRecordId = data.refRecordId;
                this.contactDetail.timeZone = data.timeZone;
                this.contactDetail.twitterId = data.twitterId;
                this.contactDetail.active = data.active;
                this.contactDetail.createdDt = data.createdDt;
                this.contactDetail.lastUpdatedDt = data.lastUpdatedDt;

            }
        },
        bindSelectedData: function(object, data) {
            // this.contactDetail.alternateEmailId=!data.alternateEmailId?data.alternateEmailId : '' ;
            // this.contactDetail = data;
            // alert(data.id);

            console.log("data",data);

            object.id = data.id;
            object.contactName = data.contactName;
            object.active = data.active;
            object.position = data.position;
            object.emailId = data.emailId;
            object.mobile = data.mobile;
            object.firstName = data.firstName;
            object.middleName = data.middleName;
            object.lastName = data.lastName;
            object.photo = data.photo == null ? this.defaultPhoto : data.photo;
            object.countryId = data.countryId;
            object.country = data.country;
            object.buildingName = data.buildingName;
            object.streetName = data.streetName;
            object.city = data.city;
            object.state = data.state;
            object.pincode = data.pincode;
            object.website = data.website;
            object.addedBy = data.addedBy;
            object.address1 = data.address1;
            object.streetName = data.streetName;
            object.alternateEmailId = data.alternateEmailId;
            object.alternateMobile = data.alternateMobile;
            object.company = data.company;
            object.contactId = data.contactId;
            object.dateOfBirth = data.dateOfBirth;
            object.facebookId = data.facebookId;
            object.skypeId = data.skypeId;
            object.gender = data.gender;
            object.linkedinId = data.linkedinId;
            object.officePhone = data.officePhone;
            object.phone = data.phone;
            object.refEntityId = data.refEntityId;
            object.refRecordId = data.refRecordId;
            object.timeZone = data.timeZone;
            object.twitterId = data.twitterId;
            object.userId = data.userId;
            object.vhextAccountId = data.vhextAccountId;
            object.createdDt = data.createdDt;
            object.lastUpdatedDt = data.lastUpdatedDt;
            // object.photo = data.photo == null ? this.defaultPhoto : data.photo;
        },
        uploadPhoto: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(this.$refs.profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: 'File format not supported',
                    position: 'topRight'
                });
                return false;
            }

            let formData = new FormData();
            formData.append('file', this.$refs.profile_photo.files[0]);
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('id', this.contactDetail.id);

            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/profileimage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    var result = response.data;
                    iziToast.success({
                        title: 'Success',
                        message: 'Profile photo uploaded Successfully',
                        position: 'topRight'
                    });

                    this.viewContact(this.contactDetail.id);
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Unable to update photo',
                        position: 'topRight'
                    });
                });
        }
    }
}
</script>