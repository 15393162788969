<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Corporate Group Reports</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click.prevent="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div class="row mx-2">
                    <div class="col-md-12 col-xl-12">
                        <div class="card p-1 px-1">
                            <p class="group-header-title text-left pl-1 mt-1 mb-0"><i class="fa fa-building-o" aria-hidden="true"></i>
                                Company
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3 py-3">
                        <a title="" class="report-box make-it-slow py-0" @click="$router.push('/landing/crgcisrep')">
                            <div class="row col-md-12 left-bar-tab pt-1 ml-1">
                                <div class="col-md-1 p-1 mt-2">
                                    <i class="fa fa-2x icon-b fa-file" aria-hidden="true"></i>
                                </div>
                                <div class="col-md-11 text-left align-self-center mt-2">
                                    <p class="action-link"><b>Company List</b></p>
                                    <p>Lists all companies in the Corporate Group.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-12 col-xl-12">
                        <div class="card p-1 px-1">
                            <p class="group-header-title text-left pl-1 mt-1 mb-0"><i class="fa fa-users" aria-hidden="true"></i>
                                Employee
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3 py-3">
                        <a title="" class="report-box make-it-slow py-0" @click="$router.push('/landing/crpemprep')">
                            <div class="row col-md-12 left-bar-tab pt-1 ml-1">
                                <div class="col-md-1 p-1 mt-2">
                                    <i class="fa fa-2x icon-b fa-file" aria-hidden="true"></i>
                                </div>
                                <div class="col-md-11 text-left align-self-center mt-2">
                                    <p class="action-link"><b>Employee List</b></p>
                                    <p>Lists all employees in the Corporate Group of Companies.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    
                    <div class="col-md-12 col-xl-12">
                        <div class="card p-1 px-1">
                            <p class="group-header-title text-left pl-1 mt-1 mb-0"><i class="fa fa-calendar" aria-hidden="true"></i>
                                Leave and Time
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3 py-3">
                        <a title="" class="report-box make-it-slow py-0" @click="$router.push('/landing/crpattrep')">
                            <div class="row col-md-12 left-bar-tab pt-1 ml-1">
                                <div class="col-md-1 p-1 mt-2">
                                    <i class="fa fa-2x icon-b fa-file" aria-hidden="true"></i>
                                </div>
                                <div class="col-md-11 text-left align-self-center mt-2">
                                    <p class="action-link"><b>Employee Attendance Summary</b></p>
                                    <p>Corporate Group Employee Attendance Summary</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-3 py-3">
                        <a title="" class="report-box make-it-slow py-0" @click="$router.push('/landing/crgwtlrep')">
                            <div class="row col-md-12 left-bar-tab pt-1 ml-1">
                                <div class="col-md-1 p-1 mt-2">
                                    <i class="fa fa-2x icon-b fa-file" aria-hidden="true"></i>
                                </div>
                                <div class="col-md-11 text-left align-self-center mt-2">
                                    <p class="action-link"><b>Employee Work Timeline Summary</b></p>
                                    <p>Corporate Group Employee Work Timeline Summary</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    
                    <div class="col-md-12 col-xl-12">
                        <div class="card p-1 px-1">
                            <p class="group-header-title text-left pl-1 mt-1 mb-0"><i class="fa fa-tasks" aria-hidden="true"></i>
                                Task and Time Sheet
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3 py-3">
                        <a title="" class="report-box make-it-slow py-0" @click="$router.push('/landing/crpeuprep')">
                            <div class="row col-md-12 left-bar-tab pt-1 ml-1">
                                <div class="col-md-1 p-1 mt-2">
                                    <i class="fa fa-2x icon-b fa-file" aria-hidden="true"></i>
                                </div>
                                <div class="col-md-11 text-left align-self-center mt-2">
                                    <p class="action-link"><b>Employee Utilization Summary</b></p>
                                    <p>Corporate Group Employee Utilization & Performance Report</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-3 py-3">
                        <a title="" class="report-box make-it-slow py-0" @click="$router.push('/landing/cgremptshsbmsttrep')">
                            <div class="row col-md-12 left-bar-tab pt-1 ml-1">
                                <div class="col-md-1 p-1 mt-2">
                                    <i class="fa fa-2x icon-b fa-file" aria-hidden="true"></i>
                                </div>
                                <div class="col-md-11 text-left align-self-center mt-2">
                                    <p class="action-link"><b>Employee Timesheet Submission Stats</b></p>
                                    <p>Corporate Group Employee Timesheet Submission Statistics (Periodic)</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-12 col-xl-12">
                        <div class="card p-1 px-1">
                            <p class="group-header-title text-left pl-1 mt-1 mb-0"><i class="fa fa-money" aria-hidden="true"></i>
                                Effort and Cost
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3 py-3">
                        <a title="" class="report-box make-it-slow py-0" @click="$router.push('/landing/crgempeffcstrep')">
                            <div class="row col-md-12 left-bar-tab pt-1 ml-1">
                                <div class="col-md-1 p-1 mt-2">
                                    <i class="fa fa-2x icon-b fa-file" aria-hidden="true"></i>
                                </div>
                                <div class="col-md-11 text-left align-self-center mt-2">
                                    <p class="action-link"><b>Employee Effort and Cost Analysis</b></p>
                                    <p>Employee-wise Effort and Cost Analysis in Corporate Group Companies (Periodic)</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>