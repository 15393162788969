<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Automation Config Setting</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4" spinner-variant="primary">
                <div class="form-row">
                    <div class="col-lg-8 col-xl-8 mb-2">
                        <div class="card h-100">
                            <div class="card-body py-3 px-0">
                                <div id="profile" class="pb-0 pt-0">
                                    <div class="row mb-2 mt-2 text-left">
                                        <div class="col-sm-12">
                                            <b-tabs content-class="mt-3" >
                                                <!-- <b-tab title="Initial Setting">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Country</label>
                                                                    </b-col>
                                                                    <b-col v-if="selEntity == 'country_setting'" cols="6" class="pl-0">
                                                                        <select v-model="automationSetting.countryId" class="form-control mt-1" id="countryId">
                                                                            <option value="0">Select</option>
                                                                            <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label class="m-0">{{ getCountry }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="selEntity == 'country_setting'">
                                                                            <a href="javascript:" @click="cancelAutomationSetting" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('country_setting', automationSetting.countryId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('country_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Time Zone</label>
                                                                    </b-col>
                                                                    <b-col v-if="selEntity == 'timezone_setting'" cols="6" class="pl-0">
                                                                        <Select2 v-model="automationSetting.timeZone" :options="timeZones" :settings="{width:'100%'}" />
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label class="m-0">{{ getTimeZone }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="selEntity == 'timezone_setting'">
                                                                            <a href="javascript:" @click="cancelAutomationSetting" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setTextValData('timezone_setting', automationSetting.timeZone)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('timezone_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Currency</label>
                                                                    </b-col>
                                                                    <b-col v-if="selEntity == 'currency_setting'" cols="6" class="pl-0">
                                                                        <select v-model="automationSetting.currencyId" class="form-control" id="currencyId">
                                                                            <option value="0">Select</option>
                                                                            <option v-for="(info, index) in currencies" :value="info.id">{{info.value}} ({{info.currencySymbol}})</option>
                                                                        </select>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label v-if="automationSetting.currencySymbol" class="m-0">{{ getCurrency }} ({{ automationSetting.currencySymbol }})</label>
                                                                        <label v-else class="m-0">{{ getCurrency }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="selEntity == 'currency_setting'">
                                                                            <a href="javascript:" @click="cancelAutomationSetting" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('currency_setting', automationSetting.currencyId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('currency_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Effort(1 Man Day)</label>
                                                                    </b-col>
                                                                    <b-col v-if="selEntity == 'effort_conversion_hour_day_setting'" cols="6" class="pl-0">
                                                                        <div class="input-group">
                                                                            <input type="number" v-model="automationSetting.effortConversionHourDay" class="form-control" min="0" id="effortConversionHourDay">
                                                                            <div class="input-group-append">
                                                                                <div class="input-group-text">
                                                                                    <span class="mx-auto">Man Hours</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label v-if="automationSetting.effortConversionHourDay" class="m-0">{{ automationSetting.effortConversionHourDay }} Man Hours</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="selEntity == 'effort_conversion_hour_day_setting'">
                                                                            <a href="javascript:" @click="cancelAutomationSetting" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('effort_conversion_hour_day_setting', automationSetting.effortConversionHourDay)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('effort_conversion_hour_day_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-tab> -->
                                                <b-tab title="Automation Setting">
                                                    <div class="profile_custom">
                                                        <h3 class="mb-2 bg-light p-2"><strong>Attendance Automation</strong>
                                                        </h3>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Attendance Opens: Minutes Before Shift Start</label>
                                                                    </b-col>
                                                                    <b-col v-if="selEntity == 'attendance_start_lead'" cols="6" class="pl-0">
                                                                        <div class="input-group">
                                                                            <input type="number" class="xsmall-input mb-1" v-model="automationSetting.attendanceStartLead">
                                                                            <div class="input-group-append">
                                                                                <div class="xsmall-input input-group-text">
                                                                                    <span class="mx-auto">Minutes</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <label class="message-label">Attendance opens {{automationSetting.attendanceStartLead}} minutes before shift start.</label>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label v-if="automationSetting.attendanceStartLead" class="description-label">
                                                                            <span class="badge bg-primary rounded-pill px-2 py-1 font-weight-bold" title="Period in Minutes">{{automationSetting.attendanceStartLead}}
                                                                            </span> Minutes
                                                                        </label>
                                                                        <label v-else class="message-label">Set the number of minutes before the shift starts to open attendance automatically.
                                                                        </label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="selEntity == 'attendance_start_lead'">
                                                                            <a href="javascript:" @click="cancelAutomationSetting" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('attendance_start_lead', automationSetting.attendanceStartLead)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <div v-if="automationSetting.attendanceStartLead">
                                                                                <a href="javascript:" title="Delete" @click="deleteAutomationSetting(automationSetting.attendanceStartLeadId)" v-b-modal.deleteAutomationSetting class="float-right mr-1 action-link"><i class="fa fa-trash  pl-2"></i></a>
                                                                                <a href="javascript:" @click="showEdit('attendance_start_lead')" class="float-right mr-1 action-link" title="Edit Setting"><i class="fa fa-pencil"></i></a>
                                                                                
                                                                            </div>
                                                                            <div v-else>
                                                                                <a href="javascript:" @click="showNew('attendance_start_lead')" class="float-right mr-1 action-link" title="Add New Setting"><i class="fa fa-plus-square"></i></a>
                                                                            </div>
                                                                        </template>
                                                                        
                                                                    </b-col>
                                                                </b-row>
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Attendance Closes: Minutes After Shift End</label>
                                                                    </b-col>
                                                                    <b-col v-if="selEntity == 'attendance_end_lag'" cols="6" class="pl-0">
                                                                        <div class="input-group">
                                                                            <input type="number" class="xsmall-input mb-1" v-model="automationSetting.attendanceEndLag">
                                                                            <div class="input-group-append">
                                                                                <div class="xsmall-input input-group-text">
                                                                                    <span class="mx-auto">Minutes</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <label class="message-label">Attendance closes {{automationSetting.attendanceEndLag}} minutes after shift end.</label>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label v-if="automationSetting.attendanceEndLag" class="description-label">
                                                                            <span class="badge bg-primary rounded-pill px-2 py-1 font-weight-bold" title="Period in Minutes">{{automationSetting.attendanceEndLag}}
                                                                            </span> Minutes
                                                                        </label>
                                                                        <label v-else class="message-label">Set the number of minutes after the shift ends to automatically close attendance.
                                                                        </label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="selEntity == 'attendance_end_lag'">
                                                                            <a href="javascript:" @click="cancelAutomationSetting" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('attendance_end_lag', automationSetting.attendanceEndLag)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <template v-else>
                                                                            <div v-if="automationSetting.attendanceEndLag">
                                                                                <a href="javascript:" title="Delete" @click="deleteAutomationSetting(automationSetting.attendanceEndLagId)" v-b-modal.deleteAutomationSetting class="float-right mr-1 action-link"><i class="fa fa-trash  pl-2"></i></a>
                                                                                <a href="javascript:" @click="showEdit('attendance_end_lag')" class="float-right mr-1 action-link" title="Edit Setting"><i class="fa fa-pencil"></i></a>
                                                                                
                                                                            </div>
                                                                            <div v-else>
                                                                                <a href="javascript:" @click="showNew('attendance_end_lag')" class="float-right mr-1 action-link" title="Add New Setting"><i class="fa fa-plus-square"></i></a>
                                                                            </div>
                                                                        </template>
                                                                    </b-col>
                                                                </b-row>
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Auto Attendance Approval</label>
                                                                    </b-col>
                                                                    <b-col cols="6" class="pl-0">
                                                                        <b-form-checkbox class="custom-toggle mt-1" value="1" unchecked-value="0" switch v-model="automationSetting.isAutoAttendanceApproval" @change="changeSwitchState('is_auto_attendance_approval', automationSetting.isAutoAttendanceApproval)" name="check-button"></b-form-checkbox>
                                                                        <label class="message-label">Enable Auto Attendance Approval to automatically approve attendance as soon as the employee marks it.
                                                                        </label>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-tab>

                                                <!-- <b-tab title="Activity Log">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Record Activity Log</label>
                                                                    </b-col>
                                                                    <b-col cols="6" class="pl-0">
                                                                        <b-form-checkbox class="custom-toggle mt-1" value="1" unchecked-value="0" switch v-model="automationSetting.activityLog" @change="changeSwitchState('user_activity_log_setting', automationSetting.activityLog)" name="check-button"></b-form-checkbox>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="caption-title m-0">Record Change History</label>
                                                                    </b-col>
                                                                    <b-col cols="6" class="pl-0">
                                                                        
                                                                        <b-form-checkbox class="custom-toggle mt-1" value="1" unchecked-value="0"  switch v-model="automationSetting.changeHistory" @change="changeSwitchState('change_history_setting', automationSetting.changeHistory)" name="check-button"></b-form-checkbox>

                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-tab> -->
                                            </b-tabs>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 mb-2">
                        <div class="card h-100">
                            <div class="card-body p-3">
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
        <DeleteComponent id="deleteAutomationSetting" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete automation setting ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        Select2,
    },

    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entity: 'human_resource',
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            preview: null,

            activeSettingMenuId: 1,
            activeSettingMenuTitle: '',

            selEntity: '',

            showOverlay: false,
            
            elements: {
                companyInfoEditMode: false, 
                directorSettingNewMode: false, 
                directorSettingEditMode: false, 
                companyAddressInfoEditMode: false,
                companyContactInfoEditMode: false,  
                contactPersonEditMode:false,        
            },
            automationSetting: {
                countryId: 0,
                timeZone: '',
                currencyId: 0,
                currencySymbol: '',
                calendarYear: new Date(),
                financialYearStart: new Date(),
                financialYearEnd: new Date(),
                isAttendanceAutomated: 0,
                attendanceStartLead: 0,
                attendanceEndLag: 0,

                activityLog: 0,
                changeHistory: 0,
                effortConversionHourDay: 0,
            },
            automationSettingData: {
                id: '',
                automationSettingId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                dataTypeId: '',
                entityId: '',
                entity: '',
                displayName: '',
                textValue: '',
                numberValue: '',
                dateValue: '',
                startDate: '',
                endDate: '',
                description: '',
                active: true,
                createdDt: '',
                lastUpdatedDt: '',
            },
            companySettingList: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        getTimeZone() {
            let result = '-';
            if (this.timeZones.length>0 && this.automationSetting.timeZone) {
                result = this.timeZones.find(data => data.id == this.automationSetting.timeZone);
                result = result && result.text ? result.text : '-';
            }
            return result;
        },
    },
    mounted() {
        // this.lookupData();
        this.getAutomationSettings();
    },
    validations: {
        company: {
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getAutomationSettings: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/automationsettingdata', data)
                .then((response) => {
                    let result = response.data;
                    this.automationSetting = result.automationSettingData;
                    console.log("ATMSETDATA",this.automationSetting);
                    // this.setCompanySettingData();
                })
                
        },
        // lookupData: function() {
        //      let data = {
        //         projectId: this.company.projectId,
        //         vhextAccountId: this.vhextAccountId,
        //         entity: this.company.entity,
        //     }
        //     axios.post(this.$store.getters.getAPIBasePath + '/company/lookup/all', data)
        //         .then((response) => {
        //             let result = response.data;   
        //             this.industries= result.industryLookup; 
        //             this.countries = result.countryLookup;
        //             this.currencies = result.currencyLookup;                    
        //             this.timeZones = result.timeZoneLookup;

        //         })
            
        // },        
        changeSwitchState(entity, value) {
            // alert(value);
            // value = (value == 1) ? 0 : 1;
            this.$nextTick(() => {
                this.setNumberValData(entity, value);
            })
            
        },
        setNumberValData(entity, value) {
            if(!value || value <= 0){
                iziToast.error({
                    title: 'Danger',
                    message: 'Please enter a valid number greater than 0.',
                    position: 'center'
                });
                return
            }
            this.automationSettingData.entity = entity;
            this.automationSettingData.textValue = '';
            this.automationSettingData.numberValue = value;
            this.automationSettingData.dateValue = '1950-01-01';
            this.automationSettingData.startDate = '1950-01-01';
            this.automationSettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.upsertAutomationSetting();
            })
        },
        setTextValData(entity, value) {
            this.automationSettingData.entity = entity;
            this.automationSettingData.textValue = value;
            this.automationSettingData.numberValue = 0;
            this.automationSettingData.dateValue = '1950-01-01';
            this.automationSettingData.startDate = '1950-01-01';
            this.automationSettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.upsertAutomationSetting();
            })
        },
        setDateValData(entity, value) {
            this.automationSettingData.entity = entity;
            this.automationSettingData.textValue = '';
            this.automationSettingData.numberValue = 0;
            this.automationSettingData.dateValue = value + '-01-01';
            this.automationSettingData.startDate = '1950-01-01';
            this.automationSettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.upsertAutomationSetting();
            })
        },
        setStartEndDateData(entity, startDate, endDate) {
            this.automationSettingData.entity = entity;
            this.automationSettingData.textValue = '';
            this.automationSettingData.numberValue = 0;
            this.automationSettingData.dateValue = '1950-01-01';
            this.automationSettingData.startDate = startDate;
            this.automationSettingData.endDate = endDate;
            this.$nextTick(() => {
                this.upsertAutomationSetting();
            })
        },
        setDatePeriodData(entity, startDate, endDate, datePeriod) {
            this.automationSettingData.entity = entity;
            this.automationSettingData.textValue = '';
            this.automationSettingData.numberValue = 0;
            this.automationSettingData.dateValue = datePeriod;
            this.automationSettingData.startDate = startDate;
            this.automationSettingData.endDate = endDate;
            this.$nextTick(() => {
                this.upsertAutomationSetting();
            })
        },
        upsertAutomationSetting: function() {
            console.log(this.automationSettingData)
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/automationsetting/upsert', this.automationSettingData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAutomationSettings();
                        this.clearPrimeSettingData();
                    }

                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        deleteAutomationSetting: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/company/automationsetting/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAutomationSettings();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },

        clearPrimeSettingData: function() {
            this.automationSettingData.entity = '';
            this.automationSettingData.textValue = '';
            this.automationSettingData.numberValue = '';
            this.automationSettingData.dateValue = '';
            this.automationSettingData.startDate = '';
            this.automationSettingData.endDate = '';

            this.selEntity = '';
        },
        showNew: function(selEntity) {
            this.selEntity = selEntity;
        },
        showEdit: function(selEntity) {
            this.selEntity = selEntity;
        },
        cancelAutomationSetting() {
            this.selEntity = '';
            this.getAutomationSettings();
        },
        clearData: function() {
            this.elements.companyInfoEditMode = false;
            this.elements.companyContactInfoEditMode = false;
            this.elements.companyAddressInfoEditMode = false;
            this.elements.contactPersonEditMode = false;
        },
        async refreshTopMenu() {
            this.showOverlay = true;
            await this.viewCompany();
            await EventBus.$emit('onTopMenuReload');
            this.showOverlay = false;
        },
    }

}
</script>