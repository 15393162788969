<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="form-row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Employee Activity Tracking</h4>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">

                                <div class="col-4 text-left">
                                    <div class="dataTables_filter">
                                        <label class="d-block p-1 accordion-title">Employees
                                        </label>
                                    </div>
                                </div>
                                <div class="col-8 text-right">
                                    <div class="dataTables_filter">
                                        <label>
                                            <input type="search" v-model="empFilter" class="form-control form-control-sm" placeholder="Search...">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <b-table ref="employeeTable" v-model="mainTableArray" tbody-tr-class="align-items-center" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="employeeList" @row-selected="onRowSelected" :fields="employeeFields" responsive="sm" :filter="empFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template #cell(name)="data">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col-auto">
                                            <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                            <b-avatar v-else class="mr-2"></b-avatar>
                                        </div>
                                        <div class="col">
                                            <div class="mr-auto">{{ data.value }}</div>
                                            <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                                        </div>
                                    </div>
                                </template>                                                                   
                                <template v-slot:empty="user">
                                    <div class="text-center">No Employees found</div>
                                </template>
                                <template v-slot:emptyfiltered="user">
                                    <div class="text-center">{{ user.emptyFilteredText }}</div>
                                </template>
                            </b-table>

                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row">
                                    <div class="col-lg-6 col-6">
                                        <label class="d-block py-2 px-0 my-0 accordion-title" block href="javascript:">
                                            Employee Activity Log
                                        </label>
                                        
                                    </div>
                                    <div class="col-lg-6 col-6">
                                        <div class="form-row">
                                            <div class="col-8 text-right">
                                                <label class="mt-1 mb-0">
                                                    <p class="m-0">Activity Date</p>
                                                </label>
                                            </div>
                                            <div class="col-4">
                                                <input type="date" v-model="startDate" @change="getUserTimeSheet();getWorkTimelineList();getUserActivity()" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator mb-2"></div>
                                <b-card no-body class="mb-2">
                                    <b-card-body class="py-1 px-2">
                                        <div class="form-row">
                                        <div class="col-sm-2 mb-1">
                                            <div class="card p-1 h-100">
                                                <div class="card-body p-1 text-left">
                                                    <div class="row no-gutters text-center pt-1">

                                                        <div class="col-12" style="width: 50px; height: auto;">
                                                            <img v-if="this.empPhoto" class="mr-2" :src="this.empPhoto" width="80" height="80" style="border-radius: 5px">
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-5 mb-1">
                                            <div class="card p-1 h-100">
                                                <p class="group-header-title text-left pl-1 mb-1">Session Info - {{ empName }}
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-left">
                                                    <div class="ml-2">
                                                        <b-row class="mt-1">
                                                            <b-col cols="6" class="caption-title">Work Start Time
                                                            </b-col>
                                                            <b-col cols="6">
                                                                <label class="fs-12 mb-0">{{firstLoginDateTime | formatDateTimeAmPm}}</label>
                                                            </b-col>
                                                        </b-row>
                                                        <div class="separator mb-2"></div>
                                                        <b-row>
                                                            <b-col cols="6" class="caption-title">Work End Time
                                                            </b-col>
                                                            <b-col cols="6">
                                                                <label class="fs-12 mb-0">{{lastLogoutDateTime | formatDateTimeAmPm}}</label>
                                                            </b-col>                                                    
                                                        </b-row>
                                                        <div class="separator mb-2"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-5 mb-1">
                                            <div class="card p-1 h-100">
                                                <p class="group-header-title text-left pl-1 mb-1">Location
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-left">
                                                    <div class="ml-2">
                                                        <b-row class="mt-1">
                                                            <b-col cols="12">
                                                                <label class="fs-12 mb-0">{{location}}</label>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </b-card-body>
                                    <b-tabs content-class="mt-0" active-nav-item-class="font-weight-bold primary" lazy>
                                        <b-tab title="Time Sheet">
                                            <b-card-body class="py-3 px-2">
                                                <div class="card p-1">
                                                    <div class="card-body px-1 py-1">
                                                        <div class="data-tables-hide-filter">
                                                            <b-table outlined show-empty small head-variant=:primary sticky-header="35vh" :items="userTimeSheets" :fields="timeSheetFields" responsive="sm" :filter="timeSheetFilter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                                <template #cell(index)="data">
                                                                    {{ data.index + 1 }}
                                                                </template>      
                                                                <template v-slot:cell(title)="data">
                                                                    <i v-if="!data.item.endDate" class="fa fa-hand-o-right text-success mr-2" title="In Progress" aria-hidden="true"></i>
                                                                    <a class="tooltiptext action-link" href="#" :title="data.value" @click="viewTimeSheetDetails(data.item)">{{data.value}}</a>
                                                                </template>
                                                                <template v-slot:cell(description)="data">
                                                                    <div class="tooltip-container">
                                                                        <!-- Link Element -->
                                                                        <a class="tooltiptext action-link" href="#" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false" v-html="data.value">
                                                                          {{ data.value }}
                                                                        </a>

                                                                        <!-- Custom Tooltip with HTML Content -->
                                                                        <div v-if="showTooltip && data.value.length > 20" class="custom-tooltip" v-html="data.value"></div>
                                                                  </div>
                                                                </template>
                                                                <template v-slot:cell(startDate)="data">
                                                                    {{data.item.startDate | formatShortMonthDay }}, {{data.item.startDate | formatTimeFromDt }} 
                                                                    <span v-if="data.item.endDate">
                                                                     - {{data.item.endDate | formatTimeFromDt}}
                                                                    </span>
                                                                    <span v-else>
                                                                        - In Progress
                                                                    </span>
                                                                </template>                                         
                                                                <template v-slot:cell(workHour)="data">
                                                                    {{ effortHoursMinutes(data.item.workDay, data.item.workHour,data.item.workMinute) }}
                                                                </template>
                                                                <template v-slot:cell(status)="data">
                                                                    <span v-if="data.item.status == 'To Do'" class="badge badge-pill bg-light mb-1">{{data.value}}
                                                                    </span>
                                                                    <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill bg-info mb-1">{{data.value}}
                                                                    </span>
                                                                    <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-success mb-1">{{data.value}}
                                                                    </span>      
                                                                    <span v-else-if="data.item.status == 'Verified'" class="badge badge-pill badge-primary mb-1">{{data.value}}
                                                                    </span>                                               
                                                                    <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-dark mb-1">{{data.value}}
                                                                    </span>
                                                                    <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}
                                                                    </span>
                                                                    <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                                                    <span v-else>{{data.value}}</span>
                                                                </template>
                                                                <template v-slot:cell(actions)="data">
                                                                    <a type="button" class="text-center" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                                    </a>    
                                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                        <a class="dropdown-item addnew-task pb-1 py-1" @click="viewTimeSheetDetails(data.item)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Time Sheet Details</a>

                                                                        <a v-if="data.item.preparedById == userId" class="dropdown-item addnew-task py-1" @click="showTimeSheetEdit(data.item)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Time Sheet</a>
                                                                        <a class="dropdown-item addnew-task py-1" @click="deleteTimeSheet(data.item.id)" v-b-modal.myDBDeleteTimeSheet href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Time Sheet</a>
                                                                    </div>

                                                                </template>
                                                                <template v-slot:empty="scope">
                                                                    <div class="text-center">No Time Sheets found</div>
                                                                </template>
                                                                <template v-slot:emptyfiltered="scope">
                                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                </template>
                                                            </b-table>
                                                        </div>
                                                        <div class="separator mb-1"></div>
                                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.timeSheetCount}} Items</span>
                                                        <div v-if="timeSheetCount" class="text-right font-weight-bold mb-0 pr-3 py-1">Total Effort : 
                                                            <span class="text-right" text-align=right>{{calculateTotalEffort}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-tab>
                                        <b-tab title="Work Timeline">
                                            <b-card-body class="py-3 px-2">
                                                <b-table show-empty small head-variant=:primary sticky-header="45vh"  selectable :select-mode="selectMode" sort-icon-left :items="workTimeline" :fields="workTimelineFields" @row-selected="onRowSelected" responsive="sm" :sort-by.sync="sortActivityBy" :sort-desc.sync="sortDesc" :sort-direction="sortActivityDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(empTimelineStateEnum)="data">
                                                        <span v-if="data.value">{{ getTimelineState(data.value) }}</span>
                                                    </template>
                                                    <template v-slot:cell(empTimelineDt)="data">
                                                        <span v-if="data.value">{{ data.value | formatTimeAmPm }}</span>
                                                    </template>
                                                    <template v-slot:cell(empTimelineEndDt)="data">
                                                        <span v-if="data.item.empTimelineStateEnum==4 && !data.value" class="text-danger">Work Stopped</span>
                                                        <span v-else-if="data.value">{{ data.value | formatTimeAmPm }}</span>
                                                        <span v-else class="badge badge-success fs-12">{{timerDuration}}</span>
                                                    </template>     
                                                    <template v-slot:cell(timeDiff)="data">
                                                        <span >{{ getTimeDiff(data.item.empTimelineDt,data.item.empTimelineEndDt, false) }}</span>
                                                    </template>                                                                                
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No Record found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>

                                                </b-table>
                                                <div class="separator mb-1"></div>
                                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.userActivityCount}} Items</span>
                                                
                                            </b-card-body>
                                        </b-tab>
                                        <b-tab title="System Logs">
                                            <b-card-body class="py-3 px-2">
                                                <b-table show-empty small head-variant=:primary sticky-header="45vh"  selectable :select-mode="selectMode" sort-icon-left :items="userActivityLogs" :fields="userActivityLogFields" @row-selected="onRowSelected" responsive="sm" :sort-by.sync="sortActivityBy" :sort-desc.sync="sortDesc" :sort-direction="sortActivityDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template #cell(createdDt)="data">
                                                        <div class="text-nowrap">
                                                            {{ data.item.createdDt | formatDateTimeAmPm}}
                                                        </div>
                                                    </template>
                                                    <template v-slot:cell(activity)="data">
                                                        <a class="tooltiptext action-link" href="javascript:" @click="viewUserActivityLogDetails(data.item)" v-b-tooltip.hover :title="data.value">{{data.value}}</a>
                                                    </template>
                                                    <template #cell(location)="data">
                                                        <span v-b-tooltip.hover :title="data.value">{{ data.value }}</span>
                                                    </template> 
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No User Activity Log found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                                <div class="separator mb-1"></div>
                                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.userActivityCount}} Items</span>
                                                <span v-if="this.changeHistory" class="fs-11 float-right">* Change History Recorded</span>
                                                <span v-else class="fs-11 float-right text-danger">* Change History Disabled</span>

                                                
                                            </b-card-body>
                                        </b-tab>

                                    </b-tabs>
                                </b-card>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TimesheetDetailPopup v-if="showDialog" ref="timesheetDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="timesheetPopupId" @closeDialog="closeDialog" />
        <UserActivityLogDetailPopup id="empUserActivityLogDetailPopup" v-if="showDialog" ref="userActivityLogDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />

    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
import Editor from '@tinymce/tinymce-vue';
import TimesheetDetailPopup from '@/components/popup/itemdetail/TimesheetDetailPopup.vue';
import UserActivityLogDetailPopup from '@/components/popup/itemdetail/UserActivityLogDetailPopup.vue';
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        Editor,
        TimesheetDetailPopup,
        UserActivityLogDetailPopup,
    },
    data() {
        return {
            showDetOverlay: false,
            elements: {
                newMode: true,
                editMode: false,
                visible: false,
                perAreaEditMode: false,
                descVisible: false,
            },

            dataRefreshKey: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            startTime: '',
            timerDuration: '00:00:00',
            timerValue: 0,

            humanResourceId: '',
            employeeAttendanceId: '',
            empName: '',
            empPhoto: '',
            designation: '',
            empUserId: '',
            reviewPeriodEnum: '',
            reviewPeriod: '',

            loginDateTime: '',
            logoutDateTime: '',
            location: '',

            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),


            changeHistory: false,
            showDialog: false,
            showTooltip: false,

            userActivityCount: 0,
            workTimelineCount: 0,
            timeSheetCount: 0,
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},
            timesheetPopupId: 0,

            mainTableArray: [],
            users: [],
            companySettings: {},

            employeeList: [],
            employeeFields: [
                // { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },        
                // { key: 'designation', label: 'Designation', tdClass:'', sortable: true, sortDirection: 'desc' },
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },        
                { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },        
            ],

            userActivityLogs: [],
            userActivityLogFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'createdDt', label: 'Timeline', thStyle: "width: 8px", sortable: true, sortDirection: 'desc'},
                { key: 'activity', label: 'Activity',tdClass:'table-cw-100', sortable: true, sortDirection: 'desc' },
                // { key: 'userActivityLogId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'functionalEntity', label: 'Functional Area',tdClass:'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'activityLogType', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'activityBy', label: 'Activity By', sortable: true, sortDirection: 'desc' },
                // { key: 'location', label: 'Location',tdClass:'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: '',thClass:'position-relative' },
            ],
            workTimeline: [],
            workTimelineFields: [
                { key: 'index', label: 'SN' },
                { key: 'empTimelineStateEnum', label: 'Timeline State', sortable: true, sortDirection: 'desc' },
                // { key: 'task', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'empTimelineDate', label: 'Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'empTimelineDt', label: 'Start Time', sortable: true, sortDirection: 'desc' },
                { key: 'empTimelineEndDt', label: 'End Time', sortable: true, sortDirection: 'desc' },
                // { key: 'nextEmpTimelineDt', label: 'End Time', sortable: true, sortDirection: 'desc' },
                { key: 'timeDiff', label: 'Time Diff (HH:MM)', sortable: true, sortDirection: 'desc' },
                 { key: 'location', label: 'Work Location', tdClass: 'table-cw-250  text-left', sortable: true, sortDirection: 'desc' },
            ],
            userTimeSheets: [],
            timeSheetFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px",thClass:'position-relative' },            
                // { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title',tdClass:'table-cw-200',sortable: true, sortDirection: 'desc' },
                // { key: 'taskTitle', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description',tdClass:'table-cw-250',sortable: true, sortDirection: 'desc' },
                
                { key: 'startDate', label: 'Period', formatter: "formatTimeFromDt", sortable: true, sortDirection: 'desc' },
                // { key: 'endDate', label: 'End Time', formatter: "formatTimeFromDt", sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Effort (Hrs)', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                // { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: '',thClass:'position-relative' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            empFilter: null,
            timeSheetFilter: null,
            filter: null,
            filterOn: [],

            sortActivityBy: 'createdDt',
            sortActivityDirection: 'asc',

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        firstLoginDateTime() {
            const filteredItems = this.workTimeline
                .filter(item => item.empTimelineStateEnum == 1) // Filter by 'Login'
                .sort((a, b) => a.id - b.id); // Sort by id in descending order
            this.loginDateTime = filteredItems.length ? filteredItems[0].empTimelineDt : null; // Return the date of the first item
            this.location = filteredItems.length ? filteredItems[0].location : null; // Return the loccation of the first item

            return this.loginDateTime;
        },
        lastLogoutDateTime() {
            const filteredItems = this.workTimeline
                .filter(item => item.empTimelineStateEnum == 4) // Filter by 'Login'
                .sort((a, b) => b.id - a.id); // Sort by id in descending order
            this.logoutDateTime = filteredItems.length ? filteredItems[0].empTimelineDt : null; // Return the date of the first item

            return this.logoutDateTime;
        },
        calculateTotalEffort() {
            let totalWorkDay = this.userTimeSheets.reduce((sum, item) => sum + item.workDay, 0);
            let totalWorkHour = this.userTimeSheets.reduce((sum, item) => sum + item.workHour, 0);
            let totalWorkMinute = this.userTimeSheets.reduce((sum, item) => sum + item.workMinute, 0);

            if(totalWorkMinute > 59) {
                let hours = Math.floor(totalWorkMinute / 60); // Convert to hours
                let minutes = totalWorkMinute % 60; // Remaining minutes after converting to hours
                totalWorkHour = totalWorkHour + hours;
                totalWorkMinute = minutes;
            }
            
            return this.effortHoursMinutes(totalWorkDay,totalWorkHour,totalWorkMinute);
        },

    },
    mounted() {
        this.reviewPeriodEnum = this.performanceReviewPeriod[0].id;
        this.reviewPeriod = this.getMonthYear(new Date());
        this.getEmployeeList();
        this.getCompanySettings();
        this.lookupData();
    },
    beforeDestroy() {
        this.clearTimerVal();
    },

    validations: {
    },
    methods: {
        getEmployeeList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                workingDate: this.startDate,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/employeeattendancebydate/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.employeeList = result.employeeAttendanceList;
                    this.totalRows = this.employeeList.length;
                    if (this.employeeList.length>0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.empUserId, this.mainTableArray, 'employeeTable');
                        })
                    }
                })
        },
        getUserActivity: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                activityById: this.empUserId,
                startDt: this.startDate,
                endDt: this.startDate,
            }
            // alert(data.activityById);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/useractivitylog/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.userActivityLogs = result.userActivityLogList;
                    this.userActivityCount = this.userActivityLogs.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log("getUserActivity",error);
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getWorkTimelineList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                humanResourceId: this.humanResourceId,
                // employeeAttendanceId: this.employeeAttendanceId,
                empTimelineDt: this.startDate,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empattendancetimeline/list', data)
                .then((response) => {
                    this.showOverlay = false;

                    let result = response.data;

                    this.workTimeline = result.empAttendanceTimelineList;

                    console.log("WTL",this.workTimeline);

                    this.workTimelineCount = this.workTimeline.length;

                    if(this.workTimelineCount > 0){

                        let lastIndex = this.workTimeline.length-1;

                        this.startTime = this.workTimeline[lastIndex].empTimelineDt;
                        this.pageTitleClock();
                    }

                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log("getWorkTimelineList",error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getUserTimeSheet: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                preparedById: this.empUserId,
                isArchive: false,
                startDate: this.startDate, 
                endDate: this.startDate,
            }

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/usertimesheetbydaterange/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.userTimeSheets = result.userTimeSheetList;
                    this.timeSheetCount = this.userTimeSheets.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log("getUserTimeSheet",error);
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getCompanySettings: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/companysettingdata', data)
                .then((response) => {
                    let result = response.data;
                    this.companySettings = result.companySettingData;
                    if (this.companySettings.changeHistory) {
                        this.changeHistory = this.companySettings.changeHistory;
                        // localStorage.setItem("currency", this.companySettings.currencySymbol);
                    }
                })
                
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeperformance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.ratingScaleLookup = result.ratingScaleLookup;
                    this.users = result.userLookup;
                    this.performanceAreaLookup = result.empPerformanceAreaLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onRowSelected: function(items) {
            if (items && items.length > 0) {

                console.log("Sel_Emp",items);

                let data = items[0];

                this.humanResourceId = data.humanResourceId;
                this.employeeAttendanceId = data.employeeAttendanceId;
                this.empPhoto = data.photo;
                this.empName = data.name;
                this.designation = data.designation;
                this.empUserId = data.userId;

                this.getUserActivity();
                this.getWorkTimelineList();
                this.getUserTimeSheet();
            }
        },
        viewUserActivityLogDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.userActivityLogDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        viewTimeSheetDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.timesheetPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.timesheetDetailPopupRef.getDetails(this.timesheetPopupId, null);
            })
        },
        getTimelineState(id) {
            let result = this.empTimelineStateList.find(data => data.id == id);
            return result ? result.value : '';
        },
        pageTitleClock: function() {
            // console.log(this.timerDuration, this.timerValue)
            this.timerDuration = this.startTime ? this.getTimeDiff(new Date(), this.startTime, true) : '00:00:00';
            this.timerValue = setTimeout(this.pageTitleClock, 1000);
        },
        clearTimerVal() {
            clearTimeout(this.timerValue);
            this.startTime = '';
            this.timerDuration = '00:00:00';
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
    }
}
</script>